import { ChannelTypeEnum } from '../channel';
export var WorkflowTypeEnum;
(function (WorkflowTypeEnum) {
    WorkflowTypeEnum["REGULAR"] = "REGULAR";
    WorkflowTypeEnum["ECHO"] = "ECHO";
    WorkflowTypeEnum["BRIDGE"] = "BRIDGE";
})(WorkflowTypeEnum || (WorkflowTypeEnum = {}));
export var WorkflowOriginEnum;
(function (WorkflowOriginEnum) {
    WorkflowOriginEnum["NOVU_CLOUD"] = "novu-cloud";
    WorkflowOriginEnum["EXTERNAL"] = "external";
})(WorkflowOriginEnum || (WorkflowOriginEnum = {}));
export * from './workflow-creation-source.enum';

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { IsArray, IsDefined, IsEnum, IsObject, IsString } from 'class-validator';
import { WorkflowCommonsFields } from './workflow-commons-fields';
import { WorkflowOriginEnum, WorkflowTypeEnum } from '../../types';
import { WorkflowStatusEnum } from './workflow-status-enum';
export class WorkflowResponseDto extends WorkflowCommonsFields {
}
__decorate([
    IsString(),
    IsDefined(),
    __metadata("design:type", String)
], WorkflowResponseDto.prototype, "updatedAt", void 0);
__decorate([
    IsString(),
    IsDefined(),
    __metadata("design:type", String)
], WorkflowResponseDto.prototype, "createdAt", void 0);
__decorate([
    IsArray(),
    IsDefined(),
    __metadata("design:type", Array)
], WorkflowResponseDto.prototype, "steps", void 0);
__decorate([
    IsEnum(WorkflowOriginEnum),
    IsDefined(),
    __metadata("design:type", String)
], WorkflowResponseDto.prototype, "origin", void 0);
__decorate([
    IsObject(),
    IsDefined(),
    __metadata("design:type", Object)
], WorkflowResponseDto.prototype, "preferences", void 0);
__decorate([
    IsEnum(WorkflowStatusEnum),
    IsDefined(),
    __metadata("design:type", String)
], WorkflowResponseDto.prototype, "status", void 0);
__decorate([
    IsEnum(WorkflowTypeEnum),
    IsDefined(),
    __metadata("design:type", String)
], WorkflowResponseDto.prototype, "type", void 0);

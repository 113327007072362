export var FeatureFlagsKeysEnum;
(function (FeatureFlagsKeysEnum) {
    FeatureFlagsKeysEnum["IS_TEMPLATE_STORE_ENABLED"] = "IS_TEMPLATE_STORE_ENABLED";
    FeatureFlagsKeysEnum["IS_USE_MERGED_DIGEST_ID_ENABLED"] = "IS_USE_MERGED_DIGEST_ID_ENABLED";
    FeatureFlagsKeysEnum["IS_API_RATE_LIMITING_ENABLED"] = "IS_API_RATE_LIMITING_ENABLED";
    FeatureFlagsKeysEnum["IS_API_RATE_LIMITING_DRY_RUN_ENABLED"] = "IS_API_RATE_LIMITING_DRY_RUN_ENABLED";
    FeatureFlagsKeysEnum["IS_API_IDEMPOTENCY_ENABLED"] = "IS_API_IDEMPOTENCY_ENABLED";
    FeatureFlagsKeysEnum["IS_API_EXECUTION_LOG_QUEUE_ENABLED"] = "IS_API_EXECUTION_LOG_QUEUE_ENABLED";
    FeatureFlagsKeysEnum["IS_NEW_MESSAGES_API_RESPONSE_ENABLED"] = "IS_NEW_MESSAGES_API_RESPONSE_ENABLED";
    FeatureFlagsKeysEnum["IS_HUBSPOT_ONBOARDING_ENABLED"] = "IS_HUBSPOT_ONBOARDING_ENABLED";
    FeatureFlagsKeysEnum["IS_QUOTA_LIMITING_ENABLED"] = "IS_QUOTA_LIMITING_ENABLED";
    FeatureFlagsKeysEnum["IS_EVENT_QUOTA_LIMITING_ENABLED"] = "IS_EVENT_QUOTA_LIMITING_ENABLED";
    FeatureFlagsKeysEnum["IS_TEAM_MEMBER_INVITE_NUDGE_ENABLED"] = "IS_TEAM_MEMBER_INVITE_NUDGE_ENABLED";
    FeatureFlagsKeysEnum["IS_V2_ENABLED"] = "IS_V2_ENABLED";
    FeatureFlagsKeysEnum["IS_PLAYGROUND_ONBOARDING_ENABLED"] = "IS_PLAYGROUND_ONBOARDING_ENABLED";
    FeatureFlagsKeysEnum["IS_MIXPANEL_RECORDING_ENABLED"] = "IS_MIXPANEL_RECORDING_ENABLED";
    FeatureFlagsKeysEnum["IS_INTEGRATION_INVALIDATION_DISABLED"] = "IS_INTEGRATION_INVALIDATION_DISABLED";
    FeatureFlagsKeysEnum["IS_EMAIL_INLINE_CSS_DISABLED"] = "IS_EMAIL_INLINE_CSS_DISABLED";
    FeatureFlagsKeysEnum["IS_WORKFLOW_PREFERENCES_ENABLED"] = "IS_WORKFLOW_PREFERENCES_ENABLED";
    FeatureFlagsKeysEnum["IS_CONTROLS_AUTOCOMPLETE_ENABLED"] = "IS_CONTROLS_AUTOCOMPLETE_ENABLED";
    FeatureFlagsKeysEnum["IS_IMPROVED_BILLING_ENABLED"] = "IS_IMPROVED_BILLING_ENABLED";
    FeatureFlagsKeysEnum["IS_STRIPE_CHECKOUT_ENABLED"] = "IS_STRIPE_CHECKOUT_ENABLED";
})(FeatureFlagsKeysEnum || (FeatureFlagsKeysEnum = {}));
